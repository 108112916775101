import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeGetStartedService = {
  image: PrismicImage;
  title1: string;
  description: string;
};

export type TypeGetStartedLogos = {
  logo: PrismicImage;
};

export type TypeGetStartedPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;

  logos_section_header: string;
  section_title1: string;
  section_title2: string;

  how_it_works_large_img: PrismicImage;

  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  services: TypeGetStartedService[];

  quotes: Array<{
    author_name: string;
    author_position: string;
    quote: string;
    logo: PrismicImage;
  }>;
  logos: Array<TypeGetStartedLogos>;

  hero_image: PrismicImage;
  customer_stories: Array<{
    case_study: {
      document: {
        data: PrismicRawResourceData;
      };
    };
  }>;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeGetStartedQueryResult = {
  allPrismicGetStarted: {
    edges: Array<{
      node: { data: TypeGetStartedPage };
    }>;
  };
};

export const getPage = (
  data: TypeGetStartedQueryResult
): TypeGetStartedPage => {
  return data.allPrismicGetStarted.edges[0].node.data;
};
