import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "../get-started/get-started.scss";
import "../../styles/shared.scss";
import "./contact.scss";
import {
  getPage,
  TypeGetStartedPage,
  TypeGetStartedQueryResult,
} from "../../utils/queries/get_started";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import linkResolver from "../../utils/linkResolver";
import usePreviewData from "../../utils/usePreviewData";
import {
  ResourceInteruption,
  ResourceInteruptionGetStartedStatic,
} from "../../templates/resources/customers";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";
import HubspotForm from "../../components/hubspot-form";
import { HomepageLogoCarousel } from "../../components/homepage-logos/carousel";

const query = graphql`
  {
    allPrismicGetStarted {
      edges {
        node {
          data {
            page_meta_description
            title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            page_meta_thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

const GetStartedQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeGetStartedQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicGetStarted",
    true
  ) as TypeGetStartedPage;

  // const renderableResources = result.customer_stories.map((rawResource) => new Resource(rawResource.case_study.document.data));

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div className="get-started" id="pricing-contact">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title="Contact Sales"
          page_meta_description="The amazee.io sales team is available to answer your questions, demo our platform, and explore how we can help you build better digital experiences."
          page_meta_thumbnail="https://images.prismic.io/amazeeio/b9d31a8f-1e29-4282-b261-70450e36a519_amazee-io-Pricing.jpg?auto=compress,format"
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header="Contact Sales"
          image={result.hero_image}
          subheader="The amazee.io sales team is available to answer your questions, demo our platform, and explore how we can help you build better digital experiences."
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="form_section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <HubspotForm
                  key="2912"
                  formId="a3eb7360-042d-46b6-8688-104fa3adccc0"
                  portalId="5096486"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticGetStartedQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <GetStartedQueryPage location={location} data={data} />}
    />
  );
};
export default StaticGetStartedQueryPage;
